import React, { useEffect } from "react";
import Creature403 from "../../assets/403.svg";
import { Container, Creature, ErrorStatus, Title, Paragraph, Link } from "./Styled";
import { analyticsService } from "../../services/AnalyticsService";

export default function Error403() {
  useEffect(() => {
    analyticsService.track("Login - User arrived at WAF error page", {}, () => {});
  }, []);
  return (
    <Container>
      <Creature src={Creature403} />
      <ErrorStatus>403 error</ErrorStatus>
      <Title>
        This page is not <br />
        available in your region
      </Title>
      <Paragraph>
        If you have any questions or concerns, please contact our
        <br /> support for assistance
      </Paragraph>
      <Link href="https://support.verbit.ai/hc/en-us/articles/360015514940-How-To-Contact-Verbit-Customer-Support">Contact Support</Link>
    </Container>
  );
}
