import mixpanel from "mixpanel-browser";
import { mixpanelApiKey } from "../utils/env";
import LogRocket from "logrocket";

class AnalyticsService {
  init() {
    try {
      if (!mixpanelApiKey) {
        throw new Error("Mixpanel API key is missing");
      }
      mixpanel.init(mixpanelApiKey);
    } catch (error) {
      console.error("Mixpanel failed to initialize:", error);
    }
  }

  initLogRocket() {
    try {
      LogRocket.init("ofbugl/login-5lgwr", {
        network: {
          requestSanitizer: (request) => {
            if (request.body) {
              try {
                const body = JSON.parse(request.body);
                if (body.password) {
                  body.password = "[REDACTED]"; // Redacting the password
                  request.body = JSON.stringify(body);
                }
              } catch (e) {
                console.error("Error parsing request body:", e);
              }
            }
            if (request.headers?.password) {
              request.headers.password = "[REDACTED]"; // Redacting the password
            }
            return request;
          },
          responseSanitizer: (response) => {
            if (response.body) {
              try {
                const body = JSON.parse(response.body);
                if (body.password) {
                  body.password = "[REDACTED]"; // Redacting the password
                  response.body = JSON.stringify(body);
                }
              } catch (e) {
                console.error("Error parsing response body:", e);
              }
            }
            return response;
          },
        },
      });
    } catch (error) {
      console.error("LogRocket failed to initialize:", error);
    }
  }

  identify(id: any) {
    try {
      mixpanel.identify(id);
    } catch (error) {
      console.error("Mixpanel identify failed:", error);
    }
  }

  track(event: any, props: any, callback: any) {
    try {
      mixpanel.track(event, props, callback);
    } catch (error) {
      console.error("Mixpanel track failed:", error);
    }
  }
}

export const analyticsService = new AnalyticsService();
