import { CognitoUserPool } from 'amazon-cognito-identity-js';

// This is the client id for the cognito user pool. It is different for each domain.
let clientId = process.env.REACT_APP_CLIENT_ID || "";
const minLengthDomain = process.env.REACT_APP_ENV === "prod" ? 1 : 2
let splitDomain = window.location.hostname.split(".")[0].split("-");
if (splitDomain.length > minLengthDomain) {
  let poppedElement = splitDomain.length > 0 ? splitDomain.pop() : undefined;
  clientId = poppedElement ? process.env["REACT_APP_CLIENT_ID_" + poppedElement.toUpperCase()] || "" : clientId;
}

// While working on localhost, we use the  qa client id and pool id.
const poolData = {
  UserPoolId: process.env.REACT_APP_POOL_ID || "",
  ClientId: clientId,
  mandatorySignIn: false,

  cookieStorage: {
    domain: 'devforce.verbit.co',
    path: '/',
    expires: 365,
  },
}

export default new CognitoUserPool(poolData);