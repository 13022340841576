import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { initRollbar } from "./utils/rollbar";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { analyticsService } from "./services/AnalyticsService";

const privateFieldNames = ["AuthParameters"];

// Randomize logrocket sessions recording to 1/10
// if (Math.floor(Math.random() * 10) + 1 === 5) {
//   const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames);
//   LogRocket.init("ofbugl/login-5lgwr", {
//     network: {
//       requestSanitizer,
//       responseSanitizer,
//     },
//   });
// }

analyticsService.init()

initRollbar(process.env.REACT_APP_ENV, process.env.REACT_APP_ROLLBAR_TOKEN, process.env.REACT_APP_GIT_COMMIT);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
