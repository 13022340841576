import styled from "styled-components";

interface InputWrapperProps {
  marginBottom?: string;
}

interface InputTextProps {
  Width?: string;
  isError?: boolean;
  Img?: string;
}

interface IconProps {
  Cursor?: string;
}

interface ArrowProps {
  visibility?: string;
}

interface ResendProps {
  Color?: string;
  Decoration?: string;
}

interface FieldWrapperProps {
  display?: string;
}

interface ParagraphProps {
  visibility?: string;
}

export const Container = styled.div`
  width: 300px;
  flex: 1;
  align-self: center;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  line-height: 30px;
  text-align: left;
  color: #0c2240;
  align-self: flex-start;
  margin-left: 56px;
  margin-bottom: 0;
  margin-top: 16px;
  padding-bottom: 3px;
  background: linear-gradient(270.09deg, #030d69 -2%, #194ddd 57.73%, #573aa6 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: #020219;
  text-align: left;
`;

export const Logo = styled.img`
  width: 78px;
  height: 27px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  height: 20px;
  width: 308px;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 20px;
  color: #10264a;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  height: 40px;
  margin-bottom: ${(props) => props.marginBottom};
`;

export const InputText = styled.input<InputTextProps>`
  width: ${(props) => props.Width};
  height: 40px;
  border: 1px solid;
  border-color: ${(props) => (props.isError ? "#7396ba" : "#EB0011")};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  background-image: url(${(props) => props.Img});
  background-repeat: no-repeat;
  background-position: 97%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
`;

export const Button = styled.button`
  width: 308px;
  height: 48px;
  background: #28d8c5;
  color: #10264a;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const Icon = styled.img<IconProps>`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  width: 17px;
  height: 17px;
  cursor: ${(props) => props.Cursor};
`;

export const Arrow = styled.img<ArrowProps>`
  align-self: flex-start;
  margin-left: 56px;
  cursor: pointer;
  visibility: ${(props) => props.visibility};
`;

export const ErrorText = styled.p`
  color: #eb0011;
  font-size: 14px;
  text-align: left;
  width: 293px;
`;

export const SupportMessage = styled.div`
  margin-top: 36px;
  font-size: 13px;
  line-height: 18px;
  color: #757584;
  text-align: left;
`;

export const ParagraphTop = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: left;
  color: #757584;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 0px;
  white-space: pre;
`;

export const ParagraphBottom = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: left;
  color: #757584;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 10px;
  white-space: pre;
`;

export const Resend = styled.p<ResendProps>`
  color: ${(props) => props.Color};
  cursor: pointer;
  text-decoration: ${(props) => props.Decoration};
`;

export const FieldWrapper = styled.div<FieldWrapperProps>`
  display: ${(props) => props.display};
`;

export const Box = styled.div`
  width: 420px;
  height: 422px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const Paragraph = styled.p<ParagraphProps>`
  font-size: 14px;
  color: #757584;
  font-family: "Inter", sans-serif;
  text-align: left;
  align-self: flex-start;
  margin-left: 56px;
  visibility: ${(props) => props.visibility};
`;

export const RedirectMessage = styled.p`
  font-size: 14px;
  color: #030d69;
  width: 308px;
  margin-top: -20px;
  text-align: left;
`;

export const Bold = styled.b`
  font-weight: 600;
`;
