import Rollbar from "rollbar";

export function initRollbar(env: any, accessToken: any, commit_sha1: any) {
  (window as any).Rollbar = Rollbar.init({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: env,
        code_version: commit_sha1,
        source_map_enabled: true,
    },
});
}