import { Routes, Route } from "react-router-dom";
import { Account } from "./components/Account/Account";
import { AppContainer } from "./Styled";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";
import Error403 from "./components/Error403/Error403";
import Phone from "./components/Phone/Phone";
import ConfirmPhone from "./components/Phone/ConfirmPhone";
import Verification from "./components/Phone/Verification";
import { Amplify } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { theme } from "./theme";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";

// Initialize Amplify
Amplify.configure(awsExports);

function App() {
    
  return (
    <AmplifyProvider theme={theme}>
      <Account>
        <AppContainer>
          <Header />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/phone" element={<Phone />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/confirmphone" element={<ConfirmPhone />} />
            <Route path="/redirect_to/:url" element={<Login />} />
            <Route path="/region-restricted" element={<Error403 />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </AppContainer>
      </Account>
    </AmplifyProvider>
  );
}

export default App;
