import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
`;

export const Creature = styled.img`
  width: 300px;
`;

export const ErrorStatus = styled.h3`
  font-size: 16px;
  font-weight: 900;
  color: #2f6cda;
  text-transform: uppercase;
  margin-top: 23px;
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
`;

export const Title = styled.h1`
  font-size: 44px;
  line-height: 55px;
  font-weight: 800;
  background: linear-gradient(270.09deg, #2b7abb -2%, #194ddd 44.65%, #573aa6 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
`;

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #4e4e5f;
  line-height: 24px;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

export const Link = styled.a`
  font-size: 14px;
  color: #1a4af0;
  font-weight: 400;
  text-decoration: underline;
  margin-top: 32px;
  cursor: pointer;
`;
