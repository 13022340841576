import React, { useContext, useState, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";
import LogRocket from "logrocket";
import { AccountContext } from "../Account/Account";
import Loader from "../Loader/Loader";
import Button from "../Button/Button";
import { EllipsisContainer } from "../Ellipsis/Styled";
import {
  Title,
  Form,
  LabelContainer,
  Label,
  InputText,
  Icon,
  Arrow,
  InputWrapper,
  ErrorText,
  FieldWrapper,
  Box,
  Paragraph,
  RedirectMessage,
  Bold,
} from "../GeneralStyles/GeneralStyles";
import LeftArrow from "./images/Left_Arrow.svg";
import EyeOff from "./images/Eye_Off.svg";
import EyeOn from "./images/Eye_On.svg";
import { baseUrl, loginUrl, registrationUrl } from "../../utils/env";
import { useFocus } from "../../utils/useFocus";
import { analyticsService } from "../../services/AnalyticsService";
import { IAccountContext } from "../types";
import { useAccountHelpers } from "../../hooks/useAccountHelpers";
import { useAccountAndSearchParams } from "../../hooks/useAccountAndSearchParams";

const Login = () => {
  // Custom hooks
  const {
    validateUrl,
    cleanUrl,
    truncate,
    handleEmailChange,
    trackForgotPassword,
    handlePasswordChange,
    handleShowPassword,
    handleMouseDownPassword,
    setupPage,
    updateRedirectUrl,
    initiateLoginProcess,
  } = useAccountHelpers();
  const { handleRedirects, handleLoginCallback, handleLoginErrors } = useAccountAndSearchParams();

  // URL search parameters
  const [searchParams, setSearchParams] = useSearchParams();
  const urlError = searchParams.get("error_description");
  const isRedirected = searchParams.get("redirected");
  const redirect_url = validateUrl(searchParams.get("redirect_to"));
  const decoded_url = decodeURI(redirect_url);
  const mfaRequired = searchParams.get("collect");

  const location = useLocation();

  const clientId = useMemo(() => {
    const acidQueryParam = searchParams.get("acid");
    if (acidQueryParam) {
      return acidQueryParam;
    }

    const idParam = /^\/oauth2\/callback\/(.+)$/g.exec(location.pathname)?.[1];
    if (idParam) {
      return idParam;
    }

    return null;
  }, [location.pathname, searchParams]);
  const code = searchParams.get("code");

  // Component state
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [ssoLogin, setSsoLogin] = useState(true);
  const [redirectedToUS, setRedirectedToUS] = useState(false);
  const [passwordRef, setPasswordFocus] = useFocus();

  // Context
  const { authenticate, values, setValues, redirectUser, redirectInProgress } = useContext(AccountContext) as IAccountContext;

  // Helper functions

  const performLoginRequest = () => {
    axios.get(`${loginUrl}/api/v1/user/sso-callback/${clientId}?code=${code}`).then(processLoginSuccess).catch(processLoginFailure);
  };

  const processLoginSuccess = (res: { data: { token: any } }) => {
    const redirectUrl = validateUrl(localStorage.getItem("redirect_to"));
    setValues({ ...values, loading: true, idToken: res.data.token, redirect_url: redirectUrl });
    redirectUser(res.data.token, null);
    localStorage.removeItem("redirect_to");
  };

  const processLoginFailure = (err: any) => {
    console.error(err);
    setValues({ ...values, loading: false, errorMsg: "Login failed. Contact your IT admin for assistance." });
    setShowLoader(false);
    setLoading(false);
  };

  const redirectToSignUp = () => {
    setLoading(true);
    if (values.email) {
      window.location.href = `${registrationUrl}?username=${encodeURIComponent(values.email)}`;
    }
  };

  const buttonText = () => {
    if (ssoLogin) {
      return "Next";
    } else {
      setPasswordFocus();
      return "Login";
    }
  };

  // This function is called when the user clicks the back button and resets the UI state
  const handleBack = () => {
    setSsoLogin(true);
    setValues({ ...values, errorMsg: false });
    setLoading(false);
    setShowLoader(false);
    setDisabled(false);
  };

  const errorMessageElement = useMemo(() => {
    if (values.errorMsg) {
      return (
        <ErrorText role="status" aria-live="polite">
          {values.errorMsg}
        </ErrorText>
      );
    }
    return <ErrorText role="status" aria-live="polite" aria-hidden="true"></ErrorText>;
  }, [values.errorMsg]);

  let ssoRedirect: AxiosRequestConfig = {
    method: "post",
    url: `${loginUrl}/api/v2/user/sso-redirect`,
    data: {
      email: values.email,
    },
    withCredentials: true,
  };

  const onSubmit = async (event: any) => {
    setRedirectedToUS(false);
    // This is the case where the user is logging in with email and password
    if (ssoLogin === false) {
      event.preventDefault();
      setShowLoader(true);
      setDisabled(true);
      if (values.password && values.email) {
        const startTime = Date.now();
        window.addEventListener("beforeunload", () => {
          const endTime = Date.now();
          const elapsedTime = endTime - startTime;
          console.log("logging values", values.email, elapsedTime);
          analyticsService.track("Login - Time between click and redirect", { User: values.email, elapsedTime: elapsedTime / 1000 }, () => {});
        });
        setShowLoader(true);
        setDisabled(true);
        LogRocket.identify(values.email);
        analyticsService.identify(values.email);
        try {
          // The authenticate function returns the academyUrl if the user is logging in with an academy account
          const { academyUrl: resolvedAcademyUrl } = await authenticate(values.email, values.password);
          if (resolvedAcademyUrl) {
            window.location.href = resolvedAcademyUrl;
            return;
          }
          // The redirectUser function returns the redirectUrl if the user is logging in with a non-academy account (i.e. Verbit employees)
          if (redirect_url) {
            window.location.href = `${decoded_url}`;
            return;
          }
          window.location.href = baseUrl!;
          return;
        } catch (err) {
          const isAnyWindow = window as any;
          const isAnyErr = err as any;

          // Report error to Rollbar
          isAnyWindow.Rollbar?.error("Login - Failed to login", { User: values.email }, err);

          // Reset UI states
          setLoading(false);
          setShowLoader(false);
          setDisabled(false);

          // Helper function to set error message
          const setErrorMessage = (message: string) => {
            setValues({ ...values, errorMsg: message });
          };

          switch (isAnyErr.response?.status) {
            case 401:
              setErrorMessage("Incorrect username or password");
              break;
            case 500:
            case 503:
              setErrorMessage("Temporarily unavailable");
              isAnyWindow.Rollbar?.error(`Login - Server Error: ${err}`, err);
              break;
            default:
              if (isAnyErr.message === "PreAuthentication failed with error Unconfirmed Self service user.") {
                redirectToSignUp();
              } else if (isAnyErr.message === "User is disabled.") {
                setErrorMessage("Can't log in - User has been blocked. Try again later.");
              } else {
                setErrorMessage(isAnyErr.message.replace(/"/g, ""));
              }
          }
        }
      } else {
        setValues({ ...values, errorMsg: "Please fill in required fields" });
      }
    } else {
      // This is the case where the user is logging in with SSO
      event.preventDefault();
      const startTime = Date.now();
      setShowLoader(true);
      setDisabled(true);
      if (values.email) {
        setShowLoader(true);
        setDisabled(true);
        analyticsService.identify(values.email);
        axios(ssoRedirect)
          .then((response) => {
            console.log("ssoredirect response: ", response);
            const endTime = Date.now();
            const elapsedTime = endTime - startTime;
            console.log("logging values", values.email, elapsedTime);
            analyticsService.track("Login - Time for sso query", {}, { User: values.email, elapsedTime: elapsedTime / 1000 });
            if (response.data.sso_flow == false) {
              setShowLoader(false);
              setDisabled(false);
              setSsoLogin(false);
            } else {
              analyticsService.initLogRocket();
              window.location.href = `${response.data.redirect_url}`;
            }
          })
          .catch((err) => {
            (window as any).Rollbar?.error("Login - error with sso api", { User: values.email }, err);
            setLoading(false);
            setShowLoader(false);
            setDisabled(false);
            setSsoLogin(false);
            setPasswordFocus();
          });
      } else {
        setValues({ ...values, errorMsg: "Please fill in required fields" });
      }
    }
  };

  // Effects
  useEffect(() => {
    setupPage(redirect_url);
    handleRedirects(isRedirected, redirect_url, updateRedirectUrl, setRedirectedToUS);
    handleLoginCallback(code, clientId, initiateLoginProcess, setLoading, setShowLoader, performLoginRequest);
    handleLoginErrors(urlError, setLoading, setShowLoader, setDisabled);
  }, []);

  // This effect is used to set the error message when the user is redirected to the login page
  useEffect(() => {
    if (values.errorMsg) {
      setShowLoader(false);
      setDisabled(false);
    }
  }, [values.errorMsg]);

  useEffect(() => {
    if (!ssoLogin) {
      setPasswordFocus();
    }
  }, [ssoLogin]);

  useEffect(() => {
    if (mfaRequired) {
      setValues({ ...values, collectPhone: true });
    }
  }, [mfaRequired]);

  // This effect is used to set the redirect url in the local storage and in the UI state
  useEffect(() => {
    if (redirect_url) {
      setValues({ ...values, redirectUrl: redirect_url });
      localStorage.setItem("redirect_to", redirect_url);
    }
  }, [redirect_url]);

  return (
    <>
      {loading || clientId ? (
        <Loader />
      ) : (
        <Box>
          <Arrow
            src={LeftArrow}
            alt="arrow"
            visibility={ssoLogin ? "hidden" : "visible"}
            onClick={handleBack}
            aria-label="Go back"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleBack();
              }
            }}
          />{" "}
          <Title>Login</Title>
          {ssoLogin ? (
            <Paragraph visibility={ssoLogin ? "hidden" : "visible"}>...</Paragraph>
          ) : (
            <Paragraph>{truncate(`Signing in as ${values.email}`, 45)}</Paragraph>
          )}
          {redirectedToUS && (
            <RedirectMessage>
              You’ve been redirected to the login page for US-based accounts. <Bold>Please log in and save URL for future use. </Bold>
            </RedirectMessage>
          )}
          <Form onSubmit={onSubmit}>
            <FieldWrapper display={ssoLogin ? "block" : "none"}>
              <LabelContainer>
                <Label htmlFor="emailInput" id="email">
                  Email
                </Label>
              </LabelContainer>
              <InputWrapper marginBottom={values.errorMsg ? "0" : "36px"}>
                <InputText
                  Width="308px"
                  name="emailInput"
                  autoFocus
                  tabIndex={0}
                  onChange={handleEmailChange("email")}
                  value={values.email}
                  isError={values.errorMsg ? false : true}
                  aria-required="true"
                  aria-label="Email Address"
                  aria-invalid={values.errorMsg ? "true" : "false"}
                  required
                  autoComplete="email"
                  id="emailInput"
                />
              </InputWrapper>
            </FieldWrapper>
            <FieldWrapper display={ssoLogin ? "none" : "block"}>
              <LabelContainer>
                <Label htmlFor="passwordInput" id="password">
                  Password
                </Label>
                <a
                  href={baseUrl + "/users/password/new"}
                  tabIndex={0}
                  style={{ fontSize: 14, color: "#757584", textDecoration: "none" }}
                  onClick={trackForgotPassword}>
                  Forgot Password
                </a>
              </LabelContainer>
              <InputWrapper marginBottom={values.errorMsg ? "0" : "36px"}>
                <InputText
                  Width="308px"
                  tabIndex={0}
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                  isError={values.errorMsg ? false : true}
                  aria-invalid={values.errorMsg ? "true" : "false"}
                  autoComplete="current-password"
                  ref={passwordRef}
                  autoFocus
                  aria-label="Password"
                  id="passwordInput"
                />
                <Icon
                  src={values.showPassword ? EyeOn : EyeOff}
                  aria-pressed={values.showPassword ? "true" : "false"}
                  alt="Show/Hide Password"
                  tabIndex={0}
                  role="button"
                  onClick={handleShowPassword}
                  onKeyDown={handleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                />
              </InputWrapper>
            </FieldWrapper>
            {redirectInProgress && <RedirectMessage>Redirecting to {cleanUrl(values.redirectUrl)}, this might take a moment</RedirectMessage>}
            {errorMessageElement}
            <Button type="submit" disabled={disabled} tabIndex={0}>
              {showLoader ? (
                <EllipsisContainer Background="white">
                  <div />
                  <div />
                  <div />
                </EllipsisContainer>
              ) : (
                buttonText()
              )}
            </Button>
          </Form>
        </Box>
      )}
    </>
  );
};

export default Login;
