import { Dispatch, SetStateAction, useContext } from "react";
import { AccountContext } from "../components/Account/Account";
import { IAccountContext } from "../components/types";
import { useSearchParams } from "react-router-dom";

export const useAccountAndSearchParams = () => {
  const { values, setValues } = useContext(AccountContext) as IAccountContext;

  const [searchParams, setSearchParams] = useSearchParams();

  const handleRedirects = (
    isRedirected: string | null,
    redirect_url: any,
    updateRedirectUrl: { (redirect_url: string): void; (arg0: any): void },
    setRedirectedToUS: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }
  ) => {
    if (isRedirected) setRedirectedToUS(true);
    if (redirect_url) {
      updateRedirectUrl(redirect_url);
    }
  };

  const handleLoginCallback = (
    code: string | null,
    clientId: string | null,
    initiateLoginProcess: {
      (
        setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
        setShowLoader: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }
      ): void;
      (arg0: any, arg1: any): void;
    },
    setLoading: Dispatch<SetStateAction<boolean>>,
    setShowLoader: Dispatch<SetStateAction<boolean>>,
    performLoginRequest: { (): void; (): void }
  ) => {
    if (code && clientId) {
      initiateLoginProcess(setLoading, setShowLoader);
      performLoginRequest();
    }
  };

  const handleLoginErrors = (
    urlError: any,
    setLoading: (arg0: boolean) => void,
    setShowLoader: (arg0: boolean) => void,
    setDisabled: (arg0: boolean) => void
  ) => {
    if (urlError) {
      setLoading(false);
      setShowLoader(false);
      setDisabled(false);
      processUrlError();
    } else {
      setLoading(false);
      setShowLoader(false);
      setDisabled(false);
    }
  };

  const logErrorDescription = (errorDescriptionValue: string) => {
    const equalsIndex = errorDescriptionValue.indexOf("=");
    const errorDescription = decodeURIComponent(errorDescriptionValue.slice(equalsIndex + 1));
    (window as any).Rollbar?.error("Login - sso callback error", { User: values?.email }, errorDescription);
  };

  const processUrlError = () => {
    const errorDescriptionValue = searchParams.get("error_description");
    if (errorDescriptionValue) {
      logErrorDescription(errorDescriptionValue);
    }
    setValues({ ...values, errorMsg: "Login failed. Contact your IT admin for assistance." });
  };

  return {
    handleRedirects,
    handleLoginCallback,
    handleLoginErrors,
    processUrlError,
  };
};
