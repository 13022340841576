import styled, { keyframes } from "styled-components";

interface EllipsisContainerProps {
  Background?: string;
}

const El1 = keyframes`
  from {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
  }
`;

const El2 = keyframes`
from {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(24px, 0);
        -ms-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
`;

const El3 = keyframes`
  from {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
  }
`;

export const EllipsisContainer = styled.div<EllipsisContainerProps>`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & > div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${props => props.Background || 'transparent'};
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      -webkit-animation: ${El1} 0.6s infinite;
      animation: ${El1} 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      -webkit-animation: ${El2} 0.6s infinite;
      animation: ${El2} 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      -webkit-animation: ${El2} 0.6s infinite;
      animation: ${El2} 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      -webkit-animation: ${El3} 0.6s infinite;
      animation: ${El3} 0.6s infinite;
    }
  }
`;