import { Container } from "./Styled";
import { Logo } from "../GeneralStyles/GeneralStyles";
import VerbitLogo from "../../assets/Logo.webp";

const Header = () => {
  return (
    <Container>
      <Logo src={VerbitLogo} alt="Verbit" />
    </Container>
  );
};

export default Header;