export const theme = {
  name: "my-theme",
  tokens: {
    fonts: {
      default: {
        variable: { value: "Nunito Sans, sans-serif" },
        static: { value: "Raleway, sans-serif"}
      },
      secondary: {
        variable: {value: "Raleway, sans-serif"}
      }
    },
    colors: {
      font: {
        primary: { value: "#757584" },
        secondary: { value: "#020219" },
      },
    },
  },
};
