import Cookies from "js-cookie";
import { baseUrl } from "../utils/env";
import { SetStateAction, useContext } from "react";
import { AccountContext } from "../components/Account/Account";
import { IAccountContext } from "../components/types";
import { analyticsService } from "../services/AnalyticsService";

const validUrls = ["verbit.co", "verbitext.com", "verbit.ai"];

export const useAccountHelpers = () => {
  const { values, setValues } = useContext(AccountContext) as IAccountContext;

  function checkWAFDomain(redirectUrl: any) {
    let redirectDomain = localStorage.getItem("waf-domain");
    if (redirectDomain && (redirectDomain !== window.location.hostname || redirectUrl)) {
      redirectUrl = redirectUrl ? `?redirect_to=${redirectUrl}&redirected=true` : "?redirected=true";
      window.location.href = `https://${redirectDomain}${redirectUrl}`;
    }
  }

  const setHubspotCookie = async () => {
    let scriptSource = "//js.hs-scripts.com/4023006.js";
    const script = document.createElement("script");
    script.src = scriptSource;
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    document.body.appendChild(script);
    script.onload = () => {
      const hubspotutkValue = Cookies.get("hubspotutk");
      if (hubspotutkValue) {
        Cookies.set("hubspotutk", hubspotutkValue, { expires: 30, domain: "verbit.co" });
      }
    };
  };

  const removeHubspotCookie = () => {
    Cookies.remove("hubspotutk", { domain: "verbit.co" });
  };

  const validateUrl = (url: any) => {
    if (url) {
      let parsedUrl = new URL(url);
      for (let validUrl of validUrls) {
        if (parsedUrl.hostname.endsWith(validUrl)) {
          return url;
        }
      }
    }
    return null;
  };

  const cleanUrl = (url: any) => {
    if (!url) {
      if (baseUrl) {
        return baseUrl.replace("https://", "");
      } else {
        return "";
      }
    }

    url = url.replace("https://", "");

    let parts = url.split("co")[0] || url.split("ai")[0];
    return parts.split("?")[0] + (url.includes("co") ? "co" : "ai");
  };

  function truncate(str: any, n: any) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  const trackForgotPassword = (e: any) => {
    e.preventDefault();
    analyticsService.track("Forgot Password", { User: values.email }, () => {});
    window.open(`${baseUrl}/users/password/new`, "_self");
  };

  const handleShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };

  const handlePasswordChange = (prop: any) => (e: any) => {
    setValues({ ...values, [prop]: e.target.value.replace(/\s/g, ""), errorMsg: "" });
  };

  const handleEmailChange = (prop: any) => (e: any) => {
    setValues({ ...values, [prop]: e.target.value.replace(/\s/g, "") });
  };

  const setupPage = (redirect_url: string) => {
    document.title = "Verbit - Login";
    setHubspotCookie();
    checkWAFDomain(redirect_url);
  };

  const updateRedirectUrl = (redirect_url: string) => {
    setValues({ ...values, redirectUrl: redirect_url });
    localStorage.setItem("redirect_to", redirect_url);
  };

  const initiateLoginProcess = (
    setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
    setShowLoader: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }
  ) => {
    analyticsService.initLogRocket();
    setLoading(true);
    setShowLoader(true);
    setValues({ ...values, loading: true });
  };

  return {
    cleanUrl,
    validateUrl,
    truncate,
    trackForgotPassword,
    handleShowPassword,
    handleMouseDownPassword,
    handlePasswordChange,
    handleEmailChange,
    setupPage,
    updateRedirectUrl,
    initiateLoginProcess,
    setHubspotCookie,
    removeHubspotCookie,
  };
};
