import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%auto;
  height: 75px;
  min-height: 75px;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 40px;
  position: relative;
`;

export const Logo = styled.img`
  width: 49px;
`;
