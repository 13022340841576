import React, { useEffect, useState, useContext } from "react";
import { Container, Title, Subtitle } from "../GeneralStyles/GeneralStyles";
import { PhoneNumberField, Button } from "@aws-amplify/ui-react";
import { AccountContext } from "../Account/Account";
import { EllipsisContainer } from "../Ellipsis/Styled";
import { loginUrl } from "../../utils/env";
import { analyticsService } from "../../services/AnalyticsService";
import { IAccountContext } from "../types";

export default function Phone() {
  const [phone_number, setPhoneNumber] = useState("");
  const [country_code, setCountryCode] = useState("+1");
  const [token, setToken] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const { updatePhoneNumber, values, setValues, getSession } = useContext(AccountContext) as IAccountContext;

  useEffect(() => {
    setValues({ ...values, fullNumber: country_code + phone_number });
  }, [phone_number, country_code, values.fullNumber]);

  useEffect(() => {
    const res = getSession().then((res: any) => {
      const jwtToken = res?.accessToken.getJwtToken();
      setToken(jwtToken);
    });
  }, [getSession]);

  useEffect(() => {
    if (phone_number) {
      if (phone_number.length !== 0) {
        setIsDisabled(false);
      }
    }
  }, [phone_number]);

  useEffect(() => {
    document.title = "Verbit - Phone Collection";
    analyticsService.track("Login - Collect page visited", { User: values.email }, () => {});
    setValues({ ...values, errorMsg: null });
    if (!values.email) {
      window.location.href = loginUrl!;
    }
  }, []);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.charAt(0) === "0") {
      setPhoneNumber(e.currentTarget.value.slice(1));
    } else {
      setPhoneNumber(e.currentTarget.value);
    }
  };

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.currentTarget.value);
  };

  const updateNumber = async () => {
    setValues({ ...values, disabled: true });
    await updatePhoneNumber(values.email!, values.password!, values.fullNumber!, token);
  };

  return (
    <Container>
      <Title>Add your phone number</Title>
      <Subtitle>For security reasons and future logins, please enter your phone number. You will receive a 6-digit verification code to this number.</Subtitle>
      <PhoneNumberField
        crossOrigin={"anonymous"}
        label="Phone Number"
        autoComplete="tel-national"
        onChange={handlePhoneNumberChange}
        onCountryCodeChange={handleCountryCodeChange}
        defaultCountryCode={country_code}
        defaultValue={phone_number}
        isRequired={true}
        hasError={!!values.errorMsg}
        errorMessage={values.errorMsg}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />
      <Button
        isFullWidth={true}
        isDisabled={isDisabled}
        onClick={updateNumber}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {values.disabled ? (
          <EllipsisContainer Background="white">
            <div />
            <div />
            <div />
          </EllipsisContainer>
        ) : (
          "Continue"
        )}
      </Button>
    </Container>
  );
}
