import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../Account/Account";
import OtpInput from "react-otp-input";
import { Auth } from "aws-amplify";
import Countdown, { zeroPad } from "react-countdown";
import { supportUrl } from "../../constants";
import { analyticsService } from "../../services/AnalyticsService";
import { IAccountContext } from "../types";
import { Container, Title, Subtitle, SupportMessage, ParagraphTop, Resend, ParagraphBottom, ErrorText } from "../GeneralStyles/GeneralStyles";
import Loader from "../Loader/Loader";

export default function Verification() {
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { values, confirmSignUp, cognitoUserObj, setValues, code, setCode } = useContext(AccountContext) as IAccountContext;

  useEffect(() => {
    document.title = "Verbit - Verify Phone";
  }, []);

  useEffect(() => {
    if (values.errorMsg) {
      console.log("Error Message Detected:", values.errorMsg);
      setErrored(true);
      setLoading(false);
    }
  }, [values.errorMsg]);

  // Moved outside of render to avoid unnecessary redeclarations
  const timerRenderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return (
        <Resend Color="#757584">
          {"Please contact "}
          <a href={supportUrl} target="_blank" rel="noreferrer">
            Customer Support
          </a>
        </Resend>
      );
    } else {
      return (
        <Resend Color="#008A24">
          Resending in {zeroPad(minutes)}:{zeroPad(seconds)}
        </Resend>
      );
    }
  };

  const resendCode = async () => {
    analyticsService.track("Login - User clicked on re-send.", { User: values.email }, () => {});
    await Auth.verifyUserAttribute(cognitoUserObj, "phone_number")
      .then((data) => {
        analyticsService.track("Login - second code was sent.", { User: values.email }, () => {});
        setValues({ ...values, codeSentTwice: true });
      })
      .catch((err) => {
        analyticsService.track("Login - failed to send second code.", { User: values.email }, () => {});
        setValues({ ...values, codeSentTwice: true });
      });
  };

  const editPhone = () => {
    analyticsService.track("Login - user clicked on edit phone number.", { User: values.email }, () => {});
    setValues({ ...values, codeSentTwice: false });
    navigate("/phone");
  };

  const handleSubmit = async (inputCode: string) => {
    setCode(inputCode);
    if (inputCode?.length === 6) {
      setLoading(true);
      await confirmSignUp(cognitoUserObj, "phone_number", inputCode);
    }
  };

  // Check for necessary context values and log for debugging
  if (!values || Object.keys(values).length === 0) {
    console.error("Context values not provided to Verification component");
    return <div>Missing context values. Please check console for details.</div>;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <Title>Verify your phone number</Title>
          <Subtitle>
            A text message with a verification code was sent to {values.fullNumber}.<br />
            Enter the code below to confirm your phone number
          </Subtitle>
          <ParagraphTop>
            Didn't receive the code?{" "}
            {values.codeSentTwice ? (
              <Countdown date={Date.now() + 30000} renderer={timerRenderer} />
            ) : (
              <span>
                <Resend Color="#757584" Decoration="underline" onClick={resendCode}>
                  {"Resend Code"}
                </Resend>
              </span>
            )}
          </ParagraphTop>
          <ParagraphBottom>
            {"or "}
            <Resend Color="#757584" Decoration="underline" onClick={editPhone}>
              Edit phone number
            </Resend>
          </ParagraphBottom>
          <div className="otp-wrapper" style={{ display: "block" }}>
            <OtpInput
              numInputs={6}
              value={code}
              onChange={handleSubmit}
              inputStyle="otp-code__input"
              shouldAutoFocus={true}
              isInputNum={true}
              hasErrored={errored}
              errorStyle="otp-code__input--error"
              isDisabled={loading}
            />
            {errored && <ErrorText>The code entered does not match what we sent, or has expired.</ErrorText>}
            <SupportMessage>
              If you haven’t received the code, please contact{" "}
              <a href={supportUrl} target="_blank" rel="noreferrer">
                Customer Support
              </a>
            </SupportMessage>
          </div>
        </Container>
      )}
    </>
  );
}
