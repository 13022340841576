import { FlexContainer, Title } from "./Styled";
import { EllipsisContainer } from "../Ellipsis/Styled";

export default function Loader() {
  return (
    <FlexContainer>
      <Title>Loading</Title>
      <EllipsisContainer Background="#030E69">
        <div />
        <div />
        <div />
      </EllipsisContainer>
    </FlexContainer>
  );
}