import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  background: linear-gradient(270.09deg, #030d69 -2%, #194ddd 57.73%, #573aa6 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
`;
