import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Title, SupportMessage, Subtitle } from "../GeneralStyles/GeneralStyles";
import Loader from "../Loader/Loader";
import { AccountContext } from "../Account/Account";
import OtpInput from "react-otp-input";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { baseUrl, loginUrl } from "../../utils/env";
import { supportUrl } from "../../constants";
import { analyticsService } from "../../services/AnalyticsService";
import { IAccountContext } from "../types";

export default function ConfirmPhone() {
  const [code, setCode] = useState("");
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userPhone, setUserPhone] = useState(null);

  const { setValues, values, getSession } = useContext(AccountContext) as IAccountContext;

  const setAuthFlowType = () => {
    try {
      values.cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
    } catch (err) {
      window.location.href = loginUrl!;
    }
  };

  useEffect(() => {
    if (values.errorMsg) {
      setErrored(true);
      setLoading(false);
    }
  }, [values.errorMsg]);

  useEffect(() => {
    document.title = "Verbit - Confirm Phone";
    setAuthFlowType();
  }, []);

  const obscurePhone = (phoneNumber: any) => {
    let intLetter = phoneNumber.substring(0, 4);
    let endLetter = phoneNumber.substring(phoneNumber.length - 3, phoneNumber.length);
    return intLetter + "********" + endLetter;
  };

  useEffect(() => {
    const res = getSession().then((res: any) => {
        const jwtToken = res?.idToken.getJwtToken();
        const decodedToken = jwt_decode(jwtToken);
        const phone = (decodedToken as any)?.phone_number;
        if (phone) {
            setUserPhone(phone);
        }
    }).catch((err: any) => {
        console.error(err);
    });
}, [userPhone, getSession]);

  const sessionApi = async (data: any) => {
    const idToken = data.idToken.getJwtToken();

    if (idToken.length > 3900) {
      analyticsService.track("Login - Token is too large", { User: values.email, Token: idToken }, () => {});
      (window as any).Rollbar?.error("Login - Token is too large", { User: values.email, Token: idToken });
    }

    // Call 'session' api
    let config = {
      method: "post" as const,
      url: `${loginUrl}/api/v2/user/session`,
      withCredentials: true,
      headers: { "X-Requested-With": "XMLHttpRequest" },
      data: {
        cognito_jwt_token: idToken,
      },
    };

    const response = await axios(config);
    if (response.data.location) {
      setValues({ ...values, academyUrl: `${response.data.location}` });
    }
  };

  let handleSubmit = useCallback(
    (code: string) => {
      setCode(code);
      if (code?.length === 6) {
        setLoading(true);
        values.cognitoUser.sendMFACode(code, {
    onSuccess: async (data: any) => {
        analyticsService.track("Login - MFA Succesful", { User: values.email }, () => {});
        await sessionApi(data);
        if (values.redirectUrl) {
            const decoded_url = decodeURI(values.redirectUrl);
            window.location.href = `${decoded_url}`;
        }
        else if (!values.redirectUrl && !values.academyUrl) {
            window.location.href = baseUrl!;
        }
    },
    onFailure: (err: any) => {
        analyticsService.track("Login - MFA Failed", { User: values.email }, () => {});
        (window as any).Rollbar?.error("Login - MFA Failed", { User: values.email }, err);
        setErrored(true);
        setLoading(false);
        setCode("");
    },
});
      }
    },
    [values]
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <Title>Enter verification code</Title>
          <Subtitle>
            A text message with a verification code was sent to {userPhone && obscurePhone(userPhone)}. Enter the code below to confirm your phone number.
          </Subtitle>
          <div className="otp-wrapper" style={{ display: "block" }}>
            <OtpInput
              numInputs={6}
              value={code}
              onChange={handleSubmit}
              inputStyle="otp-code__input"
              shouldAutoFocus={true}
              isInputNum={true}
              hasErrored={errored}
              errorStyle="otp-code__input--error"
              isDisabled={loading}
            />
            <SupportMessage>
              If you haven’t received the code, please contact{" "}
              <a href={supportUrl} target="_blank" rel="noreferrer">
                Customer Support
              </a>
            </SupportMessage>
          </div>
        </Container>
      )}
    </>
  );
}
